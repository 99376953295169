// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {
    getAuth,
    signInWithPopup,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
    GoogleAuthProvider,
} from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import {
    getFirestore,
    query,
    getDocs,
    collection,
    where,
    addDoc,
    doc
} from "firebase/firestore";
import {authLogout, authStart, authSuccess} from "./store/actions/FirebaseAuthActions";
import settings from "./components/layouts/Quickbar/Settings";
import {FIREBASE_CONFIG} from "./settings";



export const env = 'local';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = FIREBASE_CONFIG;

//     {
//     apiKey: "AIzaSyA4zkgPyPy5Sjpzuw-vRzyMM-1prmkVjNE",
//     authDomain: "epica-b26a2.firebaseapp.com",
//     projectId: "epica-b26a2",
//     storageBucket: "epica-b26a2.appspot.com",
//     messagingSenderId: "28470028261",
//     appId: "1:28470028261:web:4707ab53a6e81fd5fff10c",
//     measurementId: "G-X1SBRCXSMN"
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

const db = getFirestore(app);



export default app;

const googleProvider = new GoogleAuthProvider();

const signInWithGoogle = async (history,dispatch) => {
    dispatch(authStart());
    try {
        const userCredential = await signInWithPopup(auth, googleProvider);
        const user = userCredential.user;
         user.getIdTokenResult().then((idTokenResult) => {
                    dispatch(authSuccess(userCredential, user, idTokenResult));
                    history.push("/")
                })
        // dispatch(authSuccess(res, user,user.accessToken));
        // set up Auth Token and HTTP_AUTHORIZATION
        sessionStorage.setItem('Auth Token', userCredential._tokenResponse.refreshToken)
        sessionStorage.setItem('HTTP_AUTHORIZATION', user.accessToken)
        const q = query(collection(db, "users"), where("uid", "==", user.uid));
        const docs = await getDocs(q);
        if (docs.docs.length === 0) {
            await addDoc(collection(db, "users"), {
                 uid: user.uid,
                  email: user.email,
                  name: user.displayName,
                  provider: user.providerData[0].providerId,
                  photoUrl: user.photoURL,
            });
        }
        history.push("/")
    } catch (err) {
        // debugger;
        alert(err.message);
        dispatch(authLogout(history));
    }
};

const createSessionRef = async (uid) => {
   const checkoutSessionsCollection = collection(doc(collection(db, "users"), uid), "checkout_sessions");

const checkoutSessionRef = await addDoc(checkoutSessionsCollection, {
    price: "9.99",
    success_url: window.location.origin,
    cancel_url: window.location.origin,
});
    return checkoutSessionRef;
}


const logInWithEmailAndPassword = async (email, password) => {
    try {
        await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};
const registerWithEmailAndPassword = async (name, email, password) => {
    try {
        const res = await createUserWithEmailAndPassword(auth, email, password);
        const user = res.user;
        await addDoc(collection(db, "users"), {
            uid: user.uid,
            name,
            authProvider: "local",
            email,
        });
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};
const sendPasswordReset = async (email) => {
    try {
        await sendPasswordResetEmail(auth, email);
        alert("Password reset link sent!");
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};
const logout = () => {
    signOut(auth);
};

export {auth,
    db,
    signInWithGoogle,
    logInWithEmailAndPassword,
    registerWithEmailAndPassword,
    sendPasswordReset,
    logout,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    query,
    addDoc,collection
};