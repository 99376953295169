import {FETCH_RESTAURANT_SUCCESS,
    FETCH_RESTAURANT_FAILURE
} from "../actions/StoreTypes";

const initState = {
    restaurants: []
}

const StoreReducer = (state = initState, action) => {
    if(action.type === FETCH_RESTAURANT_SUCCESS){
        return {
            ...state,
            restaurants: action.payload
        }
    }
    return state;
}

export default StoreReducer;

export const getRestaurant = (state) => state.StoreReducer.restaurants;