import { AUTH_SUCCESS,AUTH_FAIL,AUTH_LOGOUT,CREATE_AUTH_USER_SUCCESS,CREATE_AUTH_USER_FAIL} from "../actions/FirebaseAuthTypes";

const initState = {
    userCredential: {},
    user: {},
    idTokenResult: {},
    adminDeveloper: false,
    premiumUser: false,
    trialUser: false,
    trialStartAt: null,
    trialEndAt: null,
    isTrialEnded: false,
}

//  Todo start part of the reducer
const FirebaseAuthReducer = (state = initState, action) => {
    if(action.type === AUTH_SUCCESS){
        return {
            ...state,
            userCredential: action?.userCredential,
            user: action.user,
            idTokenResult: action?.idTokenResult,
            adminDeveloper: action?.idTokenResult.claims?.['admin_developer'] === true,
            premiumUser: action?.idTokenResult.claims?.['stripeRole'] === 'premium',
            trialUser: action?.idTokenResult.claims?.['stripeRole'] === 'trial',
            trialStartAt: action?.idTokenResult.claims?.['trial_start_at'],
            trialEndAt: action?.idTokenResult.claims?.['trial_end_at'],
            isTrialEnded: action?.idTokenResult.claims?.['stripeRole'] === 'trial_ended',


        }
    }
    if (action.type === AUTH_FAIL) {
        return {
            ...state,
            userCredential: {},
            user: {},
            idTokenResult: {},
            adminDeveloper: false,
            premiumUser: false,
            trialUser: false,
            trialStartAt: null,
            trialEndAt: null,
            isTrialEnded: false,
        }
    }if (action.type === AUTH_LOGOUT) {
        return {
            ...state,
            userCredential: {},
            user: {},
            idTokenResult: {},
            adminDeveloper: false,
            premiumUser: false,
            trialUser: false,
            trialStartAt: null,
            trialEndAt: null,
            isTrialEnded: false,
        }
    }
    if(action.type === CREATE_AUTH_USER_SUCCESS){
        return {
            ...state,
            userCredential: action?.userCredential,
            user: action?.user,
            idTokenResult: action?.idTokenResult,
            adminDeveloper: action?.idTokenResult?.claims?.['admin_developer'] === true,
            premiumUser: action?.idTokenResult?.claims?.['stripeRole'] === 'premium',
            trialUser: action?.idTokenResult?.claims?.['stripeRole'] === 'trial',
            trialStartAt: action?.idTokenResult.claims?.['trial_start_at'],
            trialEndAt: action?.idTokenResult.claims?.['trial_end_at'],
            isTrialEnded: action?.idTokenResult.claims?.['stripeRole'] === 'trial_ended',

        }
    }
    if (action.type === CREATE_AUTH_USER_FAIL) {
        return {
            ...state,
            userCredential: {},
            user: {},
            idTokenResult: {},
            adminDeveloper: false,
            premiumUser: false,
            trialUser: false,
            trialStartAt: null,
            trialEndAt: null,
            isTrialEnded: false,
        }
    }
    return state;
}

export default FirebaseAuthReducer;

export const getAuthUser = (state) => state.FirebaseAuthReducer;