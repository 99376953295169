import 'firebase/auth';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux'
import './index.scss'
// Css
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import packageVersion from '../package.json'
import App from './App';
import './assets/css/animate.min.css';
import './assets/css/style.css';
import './assets/vendors/iconic-fonts/cryptocoins/cryptocoins-colors.css';
import './assets/vendors/iconic-fonts/cryptocoins/cryptocoins.css';
import './assets/vendors/iconic-fonts/flat-icons/flaticon.css';
import './assets/vendors/iconic-fonts/font-awesome/css/all.min.css';
import {env} from './firebase'
import * as serviceWorker from './serviceWorker';
import {store,persistor} from "./store";
import {PersistGate} from "redux-persist/integration/react";
import 'react-toastify/dist/ReactToastify.css';

// Window Variables
// ------------------------------------
window.version = packageVersion
window.env = env


ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                    <App/>
                </PersistGate></Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
