import { PLAN_INFO_FETCH_SUCCESS,
    PLAN_INFO_FETCH_FAIL } from "../actions/PlanTypes";


const initState = {
     config: {
        "id": null,
        "manager": null,
        "display_name": 'null',
        "xero_id": null,
        "plan": "Pdf",
        "apps": {
            "key": null
        },
        "monthly_fee": null,
        "tier_name": null,
        "tier_display_name": null,
        "discount": null,
        "day_of_month_invoicing": null,
        "subscription_started_at": null,
        "subscription_renewal_at": null,
        "subscription_price_review_at": null,
        "is_trial": null,
        "trial_started_at": null,
        "stores": {
            "key": null
        }
    }
}

const PlanReducer = (state = initState, action) => {
    if(action.type === PLAN_INFO_FETCH_SUCCESS){
        return {
            ...state,
            config: action.payload
        }
    }
    return state;
}

export default PlanReducer;

export const getPlanInfo = (state) => state.PlanReducer.config;



