import React, {lazy, Suspense, useEffect} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import ScrollToTop from "./helpers/scroll-top";
import {BrowserRouter} from 'react-router-dom';
import {ToastContainer} from "react-toastify";
import {useSelector} from "react-redux";




const Accordions = lazy(() => import('./components/pages/Accordions'));
const Addproduct = lazy(() => import('./components/pages/Addproduct'));
const Alerts = lazy(() => import('./components/pages/Alerts'));
const Animations = lazy(() => import('./components/pages/Animations'));
const Badges = lazy(() => import('./components/pages/Badges'));
const Basictables = lazy(() => import('./components/pages/Basictables'));
const Breadcrumbs = lazy(() => import('./components/pages/Breadcrumbs'));
const Buttons = lazy(() => import('./components/pages/Buttons'));
const Cards = lazy(() => import('./components/pages/Cards'));
const Chartjs = lazy(() => import('./components/pages/Chartjs'));
const Chat = lazy(() => import('./components/pages/Chat'));
const Clientmanagement = lazy(() => import('./components/pages/Clientmanagement'));
const Comingsoon = lazy(() => import('./components/pages/Comingsoon'));
const Cropper = lazy(() => import('./components/pages/Cropper'));
const Customerlist = lazy(() => import('./components/pages/Customerlist'));
const Customerreview = lazy(() => import('./components/pages/Customerreview'));
const Dashboard = lazy(() => import('./components/pages/Dashboard'));
const Datatables = lazy(() => import('./components/pages/Datatables'));
const Defaultlogin = lazy(() => import('./components/pages/Defaultlogin'));
const Defaultregister = lazy(() => import('./components/pages/Defaultregister'));
const Draggables = lazy(() => import('./components/pages/Draggables'));
const Email = lazy(() => import('./components/pages/Email'));
const Error = lazy(() => import('./components/pages/Error'));
const Faq = lazy(() => import('./components/pages/Faq'));
const Flaticons = lazy(() => import('./components/pages/Flaticons'));
const Fontawesome = lazy(() => import('./components/pages/Fontawesome'));
const Formelements = lazy(() => import('./components/pages/Formelements'));
const Formlayouts = lazy(() => import('./components/pages/Formlayouts'));
const Formvalidation = lazy(() => import('./components/pages/Formvalidation'));
const Formwizard = lazy(() => import('./components/pages/Formwizard'));
const Googlechart = lazy(() => import('./components/pages/Googlechart'));
const Googlemaps = lazy(() => import('./components/pages/Googlemaps'));
const Invoice = lazy(() => import('./components/pages/Invoice'));
const Invoicedetail = lazy(() => import('./components/pages/Invoicedetail'));
const Invoicelist = lazy(() => import('./components/pages/Invoicelist'));
const Lockscreen = lazy(() => import('./components/pages/Lockscreen'));
const Materialize = lazy(() => import('./components/pages/Materialize'));
const Menucatalogue = lazy(() => import('./components/pages/Menucatalogue'));
const Menugrid = lazy(() => import('./components/pages/Menugrid'));
const Menulist = lazy(() => import('./components/pages/Menulist'));
const Modallogin = lazy(() => import('./components/pages/Modallogin'));
const Modalregister = lazy(() => import('./components/pages/Modalregister'));
const Modals = lazy(() => import('./components/pages/Modals'));
const Orders = lazy(() => import('./components/pages/Orders'));
const Pagination = lazy(() => import('./components/pages/Pagination'));
const Portfolio = lazy(() => import('./components/pages/Portfolio'));
const Preloaders = lazy(() => import('./components/pages/Preloaders'));
const Productdetail = lazy(() => import('./components/pages/Productdetail'));
const Progressbars = lazy(() => import('./components/pages/Progressbars'));
const Rangeslider = lazy(() => import('./components/pages/Rangeslider'));
const Rating = lazy(() => import('./components/pages/Rating'));
const Restaurantlist = lazy(() => import('./components/pages/Restaurantlist'));
const RestaurantLists = lazy(() => import('./components/pages/RestaurantLists'));
const AddPdfMenu = lazy(() => import('./components/pages/AddPdfMenu'));
const DownloadQrCode = lazy(() => import('./components/pages/DownloadQrCode'));
const Sales = lazy(() => import('./components/pages/Sales'));
const Sliders = lazy(() => import('./components/pages/Sliders'));
const Socialactivity = lazy(() => import('./components/pages/Socialactivity'));
const Stockmanagement = lazy(() => import('./components/pages/Stockmanagement'));
const Sweetalerts = lazy(() => import('./components/pages/Sweetalerts'));
const Tabs = lazy(() => import('./components/pages/Tabs'));
const Toast = lazy(() => import('./components/pages/Toast'));
const Todolist = lazy(() => import('./components/pages/Todolist'));
const Tour = lazy(() => import('./components/pages/Tour'));
const Typography = lazy(() => import('./components/pages/Typography'));
const Userprofile = lazy(() => import('./components/pages/Userprofile'));
const Vectormaps = lazy(() => import('./components/pages/Vectormaps'));
const Webanalytics = lazy(() => import('./components/pages/Webanalytics'));
const Widgets = lazy(() => import('./components/pages/Widgets'));
const AddPlan = lazy(() => import('./components/pages/AddPlan'));










// home pages
function Urls() {
    const {adminDeveloper} = useSelector(state => state.FirebaseAuthReducer);


    return (
        <div>
            <BrowserRouter basename={''}>
                <Router basename={''}>
                    <ScrollToTop>
                        <Suspense
                            fallback={
                                <div className="Epica-preloader-wrapper">
                                    <div className="Epica-preloader">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            }
                        >
                            <Switch>
                                {/*<Route exact path="/" component={Dashboard}/>*/}
                                <Route exact path="/" component={AddPlan}/>
                                <Route path="/add-plan" component={AddPlan}/>
                                <Route path="/restaurant-list" component={Restaurantlist}/>
                                <Route path="/restaurant-lists" component={RestaurantLists}/>
                                <Route path="/add-pdf-menu" component={AddPdfMenu}/>
                                <Route path="/download-qr-code" component={DownloadQrCode}/>
                                <Route path="/default-login" component={Defaultlogin}/>
                                <Route path="/default-register" component={Defaultregister}/>
                                <Route path="/error" component={Error}/>

                                {(adminDeveloper) ? (<>
                                    <Route path="/user-profile" component={Userprofile}/>
                                    <Route path="/accordions" component={Accordions}/>
                                    <Route path="/add-product" component={Addproduct}/>
                                    <Route path="/alerts" component={Alerts}/>
                                    <Route path="/animations" component={Animations}/>
                                    <Route path="/badges" component={Badges}/>
                                    <Route path="/basic-tables" component={Basictables}/>
                                    <Route path="/breadcrumbs" component={Breadcrumbs}/>
                                    <Route path="/buttons" component={Buttons}/>
                                    <Route path="/cards" component={Cards}/>
                                    <Route path="/chartjs" component={Chartjs}/>
                                    <Route path="/chat" component={Chat}/>
                                    <Route path="/cropper" component={Cropper}/>
                                    <Route path="/customer-list" component={Customerlist}/>
                                    <Route path="/customer-review" component={Customerreview}/>
                                    <Route path="/data-tables" component={Datatables}/>
                                    <Route path="/draggables" component={Draggables}/>
                                    <Route path="/email" component={Email}/>
                                    <Route path="/flaticons" component={Flaticons}/>
                                    <Route path="/fontawesome" component={Fontawesome}/>
                                    <Route path="/form-elements" component={Formelements}/>
                                    <Route path="/form-layouts" component={Formlayouts}/>
                                    <Route path="/form-validation" component={Formvalidation}/>
                                    <Route path="/form-wizard" component={Formwizard}/>
                                    <Route path="/google-maps" component={Googlemaps}/>
                                    <Route path="/invoice-detail" component={Invoicedetail}/>
                                    <Route path="/invoice-list" component={Invoicelist}/>
                                    <Route path="/materialize" component={Materialize}/>
                                    <Route path="/menu-catalogue" component={Menucatalogue}/>
                                    <Route path="/menu-grid" component={Menugrid}/>
                                    <Route path="/menu-list" component={Menulist}/>
                                    <Route path="/modals" component={Modals}/>
                                    <Route path="/google-chart" component={Googlechart}/>
                                    <Route path="/orders" component={Orders}/>
                                    <Route path="/pagination" component={Pagination}/>
                                    <Route path="/preloaders" component={Preloaders}/>
                                    <Route path="/product-detail" component={Productdetail}/>
                                    <Route path="/progress-bars" component={Progressbars}/>
                                    <Route path="/range-slider" component={Rangeslider}/>
                                    <Route path="/rating" component={Rating}/>
                                    <Route path="/restaurant-list" component={Restaurantlist}/>
                                    <Route path="/restaurant-lists" component={Restaurantlist}/>
                                    <Route path="/sales" component={Sales}/>
                                    <Route path="/sliders" component={Sliders}/>
                                    <Route path="/social-activity" component={Socialactivity}/>
                                    <Route path="/sweet-alerts" component={Sweetalerts}/>
                                    <Route path="/tabs" component={Tabs}/>
                                    <Route path="/toast" component={Toast}/>
                                    <Route path="/todo-list" component={Todolist}/>
                                    <Route path="/tour" component={Tour}/>
                                    <Route path="/typography" component={Typography}/>
                                    <Route path="/vector-maps" component={Vectormaps}/>
                                    <Route path="/widgets" component={Widgets}/>
                                    <Route path="/client-management" component={Clientmanagement}/>
                                    <Route path="/coming-soon" component={Comingsoon}/>
                                    <Route path="/faq" component={Faq}/>
                                    <Route path="/invoice" component={Invoice}/>
                                    <Route path="/lock-screen" component={Lockscreen}/>
                                    <Route path="/modal-login" component={Modallogin}/>
                                    <Route path="/modal-register" component={Modalregister}/>
                                    <Route path="/portfolio" component={Portfolio}/>
                                    <Route path="/stock-management" component={Stockmanagement}/>
                                    <Route path="/web-analytics" component={Webanalytics}/>
                                </>) : (<></>)}
                                <Route exact component={Error}/>
                            </Switch>
                            <ToastContainer
                                position="bottom-left"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="light"
                            />
                        </Suspense>
                    </ScrollToTop>
                </Router>
            </BrowserRouter>
        </div>
    );
}

export default Urls;
