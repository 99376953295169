import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import PlanReducer from "./PlanReducer";
import StoreReducer from "./StoreReducer";
import ActiveRestaurantReducer from "./ActiveRestaurantReducer";
import PdfReducer from "./PdfReducer";
import FirebaseAuthReducer from "./FirebaseAuthReducer";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
    PlanReducer: PlanReducer,
    StoreReducer: StoreReducer,
    ActiveRestaurantReducer: ActiveRestaurantReducer,
    PdfReducer: PdfReducer,
    FirebaseAuthReducer: FirebaseAuthReducer
});

export default persistReducer(persistConfig, rootReducer);
