import React, {useEffect} from 'react';
import Urls from "./Urls";
import {toast} from "react-toastify";


function App() {
    return (<Urls/>);
}

export default App;
