import axios from 'axios';
import {toast} from "react-toastify";


const axiosInstance = axios.create({
    baseURL: process.env.ENV_API_SERVER,
    validateStatus: function (status) {
        return status >= 200 && status < 300
    },
    // cancelToken: source.token,
})

axiosInstance.interceptors.request.use(function (config) {
    const token = sessionStorage.getItem('HTTP_AUTHORIZATION')
    config.headers.AUTHORIZATION = token ? `Bearer ${token}` : null;
    config.META = token ? `Bearer ${token}` : null;
    return config;
});
axiosInstance.interceptors.response.use(response => {
    return response;
}, error => {
    // source.cancel("axios request cancelled");
    // if axios request is cancelled
    if (axios.isCancel(error)) {
        toast.error("Request canceled", {toastId: 'requestCancelled'});
        return Promise.reject(error);
    } else if (error.response.status === 401) {
        toast.error('You need to login again', {toastId: 'notAuthenticated'})
        //  navigate to login page
        sessionStorage.removeItem('Auth Token');
        sessionStorage.removeItem('HTTP_AUTHORIZATION');
        window.location.href = '/default-login'
        return null
    } else if (error.response.status === 403) {
        toast.error('You need to login again', {toastId: 'notAuthenticated'})
        //  navigate to login page
        sessionStorage.removeItem('Auth Token');
        sessionStorage.removeItem('HTTP_AUTHORIZATION');
        window.location.href = '/default-login'
        return null
    } else if (error.response.status === 502) {
        toast.error('You are having connection problems', {toastId: 'connection'})
    }
    return axios.request(error.config);
},);
export default axiosInstance;






