import axios from "../../helpers/axios";
import * as settings from "../../settings";
import {toast} from "react-toastify";
import {
    AUTH_START,
    AUTH_SUCCESS,
    AUTH_FAIL,
    AUTH_LOGOUT,
    CREATE_AUTH_USER_START,
    CREATE_AUTH_USER_SUCCESS,
    CREATE_AUTH_USER_FAIL
} from "./FirebaseAuthTypes";
import {createUserWithEmailAndPassword, signInWithEmailAndPassword} from 'firebase/auth';
import {auth} from "../../firebase";

export const authStart = () => {
    return {
        type: AUTH_START
    }
}
// const decodedToken = await auth.currentUser?.getIdTokenResult();
//
//     return !!decodedToken?.claims?.stripeRole;
export const authSuccess = (userCredential, user, idTokenResult) => {
    sessionStorage.setItem('Auth Token', user.refreshToken)
    sessionStorage.setItem('Auth Token', userCredential?._tokenResponse?.refreshToken)
    sessionStorage.setItem('HTTP_AUTHORIZATION', user?.accessToken)
    sessionStorage.setItem('idTokenResult', idTokenResult)
    sessionStorage.setItem('claims', idTokenResult.claims?.['admin_developer'])
    // todo add expiration date
    return {
        type: AUTH_SUCCESS,
        idToken: user.accessToken,
        user: user,
        userCredential: userCredential,
        idTokenResult: idTokenResult,
        adminDeveloper: idTokenResult.claims?.['admin_developer'] === true,
        premiumUser: idTokenResult.claims?.['stripeRole'] === 'premium',
        trialUser: idTokenResult.claims?.['stripeRole'] === 'trial',
        trialStartAt: idTokenResult.claims?.['trial_start_at'],
        trialEndAt: idTokenResult.claims?.['trial_end_at'],
        isTrialEnded: idTokenResult.claims?.['stripeRole'] === 'trial_ended',
    }
}

export const authFail = (error) => {
    return {
        type: AUTH_FAIL,
        error: error
    }
}

export const authLogout = (history) => {
    sessionStorage.removeItem('Auth Token');
    sessionStorage.removeItem('HTTP_AUTHORIZATION');
    localStorage.removeItem('expirationDate');
    history.push('/default-login')
    return {
        type: AUTH_LOGOUT
    }
}

export const createAuthUserStart = () => {
    return {
        type: CREATE_AUTH_USER_START
    }
}

export const createAuthUserSuccess = (userCredential, user, idTokenResult) => {
    sessionStorage.setItem('Auth Token', userCredential._tokenResponse.refreshToken)
    sessionStorage.setItem('HTTP_AUTHORIZATION', user.accessToken)
    return {
        type: CREATE_AUTH_USER_SUCCESS,
        idToken: user.accessToken,
        user: user,
        userCredential: userCredential,
        idTokenResult: idTokenResult,
        adminDeveloper: idTokenResult.claims?.['admin_developer'] === true,
        premiumUser: idTokenResult.claims?.['stripeRole'] === 'premium',
        trialUser: idTokenResult.claims?.['stripeRole'] === 'trial',
        trialStartAt: idTokenResult.claims?.['trial_start_at'],
        trialEndAt: idTokenResult.claims?.['trial_end_at'],
        isTrialEnded: idTokenResult.claims?.['stripeRole'] === 'trial_ended',
    }
}

export const createAuthUserFail = (error) => {
    return {
        type: CREATE_AUTH_USER_FAIL,
        error: error
    }
}

export const signInToFirebase = (email, password, history) => async (dispatch) => {
    dispatch(authStart());
    return signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                toast.success("Logged in successfully");
                let cc = user.getIdTokenResult().then((idTokenResult) => {
                    dispatch(authSuccess(userCredential, user, idTokenResult));
                    history.push("/")
                })

                function checkAuthTimeout(number) {
                    return dispatch => {
                        setTimeout(() => {
                            dispatch(authLogout(history));
                        }, number * 1000)
                    }
                }

                //
                dispatch(checkAuthTimeout(3600));
                // dispatch(fetchPdfMenus());
                // dispatch(fetchStoreList());
                // dispatch(fetchRestaurantList());
                // dispatch(fetchMenuList());
                // dispatch(fetchMenuCategoryList());
            }
        ).catch(
            (error) => {
                toast.error('Wrong password or email');
            }
        )
}
//  refresh auth user
export const refreshAuthUser = (history) => async (dispatch) => {

}

export const logOutFromFirebase = (history) => async (dispatch) => {
    dispatch(authLogout(history));
}

export const createAuthUser = (email, password, history) => async (dispatch) => {
    dispatch(createAuthUserStart());
    return createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in

            const user = userCredential.user;
            toast.success("Logged in successfully");
            let cc = user.getIdTokenResult().then((idTokenResult) => {
                dispatch(createAuthUserSuccess(userCredential, user, idTokenResult));
                history.push("/")
            })

            // console.log('user');
            // console.log('user', user);
            // dispatch(createAuthUserSuccess(userCredential, user,));
            // history.push("/")
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
            dispatch(createAuthUserFail(error));
        });
}







