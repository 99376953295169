import {
    PDF_MENU_FETCH_SUCCESS,
    PDF_MENU_FETCH_FAIL,
    PDF_MENU_ADD_SUCCESS,
    PDF_MENU_ADD_FAIL,
    PDF_MENU_UPDATE_SUCCESS,
    PDF_MENU_UPDATE_FAIL,
    PDF_MENU_DELETE_SUCCESS,
    PDF_MENU_DELETE_FAIL
} from "./PdfTypes";
import * as settings from "../../settings";
import axios from "../../helpers/axios";
import {toast} from "react-toastify";

export const pdfMenuFetchSuccess = (pdfMenus) => {
    return{
        type: PDF_MENU_FETCH_SUCCESS,
        payload: pdfMenus,
    };
}

export const pdfMenuFetchFailed = (error) => {
    return{
        type: PDF_MENU_FETCH_FAIL,
        payload: error,
    };
}

export const pdfMenuAddSuccess = (pdfMenu) => {
    return{
        type: PDF_MENU_ADD_SUCCESS,
        payload: pdfMenu,
    };
}

export const pdfMenuAddFailed = (error) => {
    return{
        type: PDF_MENU_ADD_FAIL,
        payload: error,
    };
}

export const pdfMenuUpdateSuccess = (pdfMenu) => {
    return{
        type: PDF_MENU_UPDATE_SUCCESS,
        payload: pdfMenu,
    };
}

export const pdfMenuUpdateFailed = (error) => {
    return{
        type: PDF_MENU_UPDATE_FAIL,
        payload: error,
    };
}

export const pdfMenuDeleteSuccess = (pdfMenuId) => {
    return{
        type: PDF_MENU_DELETE_SUCCESS,
        payload: pdfMenuId,
    };
}

export const pdfMenuDeleteFailed = (error) => {
    return{
        type: PDF_MENU_DELETE_FAIL,
        payload: error,
    };
}

export const fetchPdfMenus = (storeId) => async (dispatch) => {
    // debugger;
    axios.get(`${settings.API_SERVER}/api/stores/${storeId}/pdf-menu`).then((response) => {
        // debugger;
        dispatch(pdfMenuFetchSuccess(response.data['config']));
    }).catch((error) => {
        dispatch(pdfMenuFetchFailed(error));
        // toast.error("Contact Developer Please " + error.message);
    });
}

export const addPdfMenu = (pdfMenu,storeId) => async (dispatch) => {
    // add form data content type
    axios.post(`${settings.API_SERVER}/api/stores/${storeId}/pdf-menu`,pdfMenu).then((response) => {
        dispatch(pdfMenuAddSuccess(response.data['config']));
        toast.success("Pdf Menu Added Successfully")
    }).catch((error) => {
        dispatch(pdfMenuAddFailed(error));
        dispatch(fetchPdfMenus(storeId));
        toast.error("Contact Developer Please " + error.message)
        // toast.error("Contact Developer Please " + error.message);
    });
}

export const updatePdfMenu = (pdfMenu,storeId) => async (dispatch) => {
    axios.put(`${settings.API_SERVER}/api/stores/${storeId}/pdf-menu`,pdfMenu).then((response) => {
        dispatch(pdfMenuUpdateSuccess(response.data['config']));
        toast.success("Pdf Menu Updated Successfully")
    }).catch((error) => {
        dispatch(pdfMenuUpdateFailed(error));
        toast.error("Contact Developer Please " + error.message);
        dispatch(fetchPdfMenus(storeId));
        // toast.error("Contact Developer Please " + error.message);
    });
}

export const deletePdfMenu = (pdfMenuId) => async (dispatch,storeId) => {
    axios.delete(`${settings.API_SERVER}/api/stores/${storeId}/pdf-menu/${pdfMenuId}`).then((response) => {
        dispatch(pdfMenuDeleteSuccess(response.data['config']));
        toast.success("Pdf Menu Deleted Successfully")
    }).catch((error) => {
        dispatch(pdfMenuDeleteFailed(error));
        toast.error("Contact Developer Please " + error.message);
        dispatch(fetchPdfMenus(storeId));
        // toast.error("Contact Developer Please " + error.message);
    });
}



