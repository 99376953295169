import {
    PDF_MENU_FETCH_SUCCESS,
    PDF_MENU_FETCH_FAIL,
    PDF_MENU_ADD_SUCCESS,
    PDF_MENU_ADD_FAIL,
    PDF_MENU_UPDATE_SUCCESS,
    PDF_MENU_UPDATE_FAIL,
    PDF_MENU_DELETE_SUCCESS,
    PDF_MENU_DELETE_FAIL
} from "../actions/PdfTypes";

const initState = {
    config: {}
}

const PdfReducer = (state = initState, action) => {
    if(action.type === PDF_MENU_FETCH_SUCCESS){
        return {
            ...state,
            config: action.payload
        }
    }
    if (action.type === PDF_MENU_FETCH_FAIL) {
        return {
            ...state,
            config: {}
        }
    }
    if(action.type === PDF_MENU_ADD_SUCCESS){
        return {
            ...state,
            config: action.payload
        }
    }
    if (action.type === PDF_MENU_ADD_FAIL) {
        return {
            ...state,
            config: {}
        }
    }
    if(action.type === PDF_MENU_UPDATE_SUCCESS){
        return {
            ...state,
            config: action.payload
        }
    }
    if (action.type === PDF_MENU_UPDATE_FAIL) {
        return {
            ...state,
            config: {}
        }
    }
    if(action.type === PDF_MENU_DELETE_SUCCESS){
        return {
            ...state,
            config: action.payload
        }
    }
    if (action.type === PDF_MENU_DELETE_FAIL) {
        return {
            ...state,
            config: {}
        }
    }
    return state;
}

export default PdfReducer;

export const getPdf = (state) => state.PdfReducer.config;