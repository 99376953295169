export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const CREATE_AUTH_USER_START = 'CREATE_AUTH_USER_START';

export const CREATE_AUTH_USER_SUCCESS = 'CREATE_AUTH_USER_SUCCESS';

export const CREATE_AUTH_USER_FAIL = 'CREATE_AUTH_USER_FAIL';


