export const PDF_MENU_FETCH_SUCCESS = "PDF_MENU_FETCH_SUCCESS";
export const PDF_MENU_FETCH_FAIL = "PDF_MENU_FETCH_FAIL";

export const PDF_MENU_ADD_SUCCESS = "PDF_MENU_ADD_SUCCESS";

export const PDF_MENU_ADD_FAIL = "PDF_MENU_ADD_FAIL";

export const PDF_MENU_UPDATE_SUCCESS = "PDF_MENU_UPDATE_SUCCESS";

export const PDF_MENU_UPDATE_FAIL = "PDF_MENU_UPDATE_FAIL";

export const PDF_MENU_DELETE_SUCCESS = "PDF_MENU_DELETE_SUCCESS";

export const PDF_MENU_DELETE_FAIL = "PDF_MENU_DELETE_FAIL";

