import {
    SET_ACTIVE_RESTAURANT,
    SET_EMPTY_ACTIVE_RESTAURANT
} from "../actions/ActiveRestaurantTypes";
import {fetchPdfMenus} from "../actions/PdfAction";

const initialState = {
    "active_store": {
    }
}

const ActiveRestaurantReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ACTIVE_RESTAURANT:
            return {
                ...state,
                active_store: action.payload
            }
        case SET_EMPTY_ACTIVE_RESTAURANT:
            return {
                ...state,
                active_store: {}
            }
        default:
            return state
    }
}

export default ActiveRestaurantReducer;

export const getActiveRestaurant = (state) => state.ActiveRestaurantReducer.active_store;